/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState, createRef, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useErrorHandler } from "react-error-boundary";

import buttons from "pages/standard-feeds/json/standardFeedsPageButtons.json";
import typeSpecs from "pages/standard-feeds/json/typeSpecsOfStandardFeed.json";

import { StandardFeedsContext } from "pages/standard-feeds/context/StandardFeedsProvider";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";

import BasePage from "components/base-page/BasePage";
import LMButton from "components/lm-button/LMButton";
import Table from "components/table/Table";
import Header from "components/header/Header";
import Loader from "components/loader/Loader";

import useFeedHandler from "hooks/useFeedHandler";
import useSaveBeforeRouteChangeFeedPlan from "hooks/useSaveBeforeRouteChangeFeedPlan";

import useOnPageLeave from "hooks/useOnPageLeave";
import useConfirm from "hooks/useConfirm";
import useCalcSFR from "hooks/useCalcSFR";
import useToggle from "hooks/useToggle";
import useEventListener from "hooks/useEventListener";

import {
  addNewStandardFeed,
  copySelectedStandardFeed,
  removeStandardFeed,
  editStandardFeedsToAdd,
  addStandardFeedToStandardFeedsToUpdate,
  clearStandardFeeds,
  updateSelectedStandardFeed,
  updateStandardFeedsAfterCalculation,
  setStandardFeeds,
} from "pages/standard-feeds/context/standardFeedsActions";

import IStandardFeed from "interfaces/IStandardFeed";
import ITypeSpec from "interfaces/ITypeSpec";
import ITableDataRow from "interfaces/ITableDataRow";

import { BASE_STANDARD_FEEDS_URL, FEED_SMART_SUPER_ADMIN } from "assets/constants";

interface IStandardFeedsPageButton {
  id: number;
  disabled: boolean;
  purpose: string;
  text: string;
  type: string;
}

const StandardFeedsPage = (): JSX.Element => {
  const [lastUpdatedStandardFeed, setLastUpdatedStandardFeed] = useState<IStandardFeed>();
  const [standardFeedsTypeSpecs, setStandardFeedsTypeSpecs] = useState<ITypeSpec>(typeSpecs);
  const [currentSortOrder, setCurrentSortOrder] = useState<ITableDataRow[]>();
  const [newlyAddedFeedId, setNewlyAddedFeedId] = useState<number>();
  const [showCow, toggleShowCow] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isConfirmed } = useConfirm();
  const { calc, calcAll } = useCalcSFR();
  const propertiesEditableBySuperAdmin = ["supportsLfu", "supportsSfr", "feedNumber", "feedGroup",
    "sw1", "sw2", "sw3", "sw4", "sw5", "sw6", "sw7", "sw8"
  ];
    
  const uniqueParamsCombo = ["feedGroup", "feedNumber"];
  const handleError = useErrorHandler();
  const [isLoading, toggleIsLoading] = useToggle(false);

  const tableRef = createRef<HTMLTableElement>();

  const {
    customerState: { currentUser, readOnlyMode, userHiddenTableColumns },
  } = useContext(CustomerContext);

  const {
    standardFeedsState,
    standardFeedsState: { feeds, feedsToAdd, feedsToDelete, feedsToUpdate, selectedFeed },
    standardFeedsDispatch,
  } = useContext(StandardFeedsContext);

  const {
    clearFeeds,
    addNewFeed,
    removeFeeds,
    copySelectedFeed,
    getFeeds,
    editFeedsToAdd,
    addFeedToFeedsToUpdate,
    postFeeds,
    putFeeds,
    deleteFeeds,
  } = useFeedHandler(standardFeedsState);

  let keyCode = "";

  useEventListener("keyup", (e) => {
    const keyEvent = e as unknown as KeyboardEvent;
    const key = keyEvent.keyCode;

    if (key === 77 || key === 85) {
      switch (keyCode) {
        case "":
          if (key === 77) {
            keyCode = "77";
          }
          break;
        case "77":
          if (key === 85) {
            keyCode = "7785";
          }
          break;
        case "7785":
          if (key === 85) {
            keyCode = "778585";
          }
          break;
        default:
          break;
      }
    }

    if (keyCode === "778585") {
      toggleShowCow(true);
      setTimeout(() => {
        keyCode = "";
        toggleShowCow(false);
      }, 5000);
    }
  });

  const clearStandardFeedsCallback = () => {
    standardFeedsDispatch(clearStandardFeeds(clearFeeds()));
  };

  const scrollToFirstElement = useCallback(() => {
    if (tableRef.current && !selectedFeed) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [feeds.length, tableRef, selectedFeed]);

  const handleOnRouteChange = async () => {
    if (feedsToAdd.length) {
      await postFeeds(BASE_STANDARD_FEEDS_URL, feedsToAdd);
    }
  
    if (feedsToUpdate.length) {
      const customerFeedsToUpdate = feedsToUpdate.filter((_feed) => !_feed.isMixed);
  
      if (customerFeedsToUpdate.length) {
        await putFeeds(BASE_STANDARD_FEEDS_URL, customerFeedsToUpdate);
      }
    }
  
    if (feedsToDelete.length) {
      await deleteFeeds(BASE_STANDARD_FEEDS_URL, feedsToDelete);
    }
  
    clearStandardFeedsCallback();
  };

  const unsaved = !!(feedsToAdd.length || feedsToDelete.length || feedsToUpdate.length);
  useOnPageLeave(unsaved);

  const onResetChanges = useCallback(async () => {
    standardFeedsDispatch(setStandardFeeds(await getFeeds(BASE_STANDARD_FEEDS_URL)));
    clearStandardFeedsCallback();
  }, []);

  useSaveBeforeRouteChangeFeedPlan({
    when: unsaved,
    callback: handleOnRouteChange,
    resetChanges: onResetChanges,
  });

  const handleCalcSFR = useCallback(async () => {
    if (selectedFeed) {
      try {
        const calculatedFeed = await calc(selectedFeed);
        if (calculatedFeed) {
          standardFeedsDispatch(
            updateStandardFeedsAfterCalculation(calculatedFeed, currentSortOrder)
          );
          setLastUpdatedStandardFeed(calculatedFeed);
        }
      } catch (error) {
        return handleError(error);
      }
    }
  }, [selectedFeed]);

  const handleCalcAllSFR = useCallback(async () => {
    const answer = await isConfirmed(t("calculateAllSfrWarning"));
    if (answer) {
      try {
        const result = await calcAll();

        if (!result || !result.link || !result.resultFile) {
          return handleError("error");
        }

        result.link.click();

        setTimeout(() => {
          URL.revokeObjectURL(result.resultFile);
        }, 5000);
      } catch (error) {
        return handleError(error);
      }
    }
  }, []);

  const handleOnClick = async (button: IStandardFeedsPageButton) => {
    switch (button.purpose) {
      case "addFeed":
        standardFeedsDispatch(addNewStandardFeed(addNewFeed()));
        scrollToFirstElement();
        break;

      case "removeFeed":
        if (!selectedFeed) {
          isConfirmed(t("noSelectedRow"), "alert");
          break;
        }
        standardFeedsDispatch(removeStandardFeed(removeFeeds()));
        break;

      case "copySelected":
        if (!selectedFeed) {
          isConfirmed(t("noSelectedRow"), "alert");
          break;
        }
        standardFeedsDispatch(copySelectedStandardFeed(copySelectedFeed()));
        break;

      case "calcSFR":
        if (!selectedFeed) {
          isConfirmed(t("noSelectedRow"), "alert");
          break;
        }
        toggleIsLoading(true);
        await handleCalcSFR();
        toggleIsLoading(false);
        break;

      case "calcAllSFR":
        toggleIsLoading(true);
        await handleCalcAllSFR();
        toggleIsLoading(false);
        break;

      default:
        break;
    }

    standardFeedsDispatch(updateSelectedStandardFeed());
  };

  const fetchStandardFeeds = useCallback(async () => {
    standardFeedsDispatch(setStandardFeeds(await getFeeds(BASE_STANDARD_FEEDS_URL)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardFeedsDispatch]);


  const handleUpdatedStandardFeed = useCallback(() => {
    if (lastUpdatedStandardFeed) {
      const isFeedSelected = lastUpdatedStandardFeed.id === selectedFeed?.id;
      if (isFeedSelected) {
        standardFeedsDispatch(updateSelectedStandardFeed(lastUpdatedStandardFeed));
      } else if (
        feedsToAdd.length &&
        feedsToAdd.some((feed: any) => feed.id === lastUpdatedStandardFeed.id)
      ) {
        standardFeedsDispatch(editStandardFeedsToAdd(editFeedsToAdd(lastUpdatedStandardFeed)));
      } else {
        standardFeedsDispatch(
          addStandardFeedToStandardFeedsToUpdate(addFeedToFeedsToUpdate(lastUpdatedStandardFeed)));
      }
      setLastUpdatedStandardFeed(undefined);
    }
  }, [feedsToAdd, lastUpdatedStandardFeed, standardFeedsDispatch]);

  const onUpdatedRow = useCallback((updatedTableRow: IStandardFeed) => {
    setLastUpdatedStandardFeed(updatedTableRow);
  }, []);

  const onSelectedRow = useCallback(
    (newSelectedRow: IStandardFeed) => {
      if (newSelectedRow) {
        standardFeedsDispatch(updateSelectedStandardFeed(newSelectedRow));
      }

      if (newSelectedRow === undefined) {
        standardFeedsDispatch(updateSelectedStandardFeed());
      }
    },
    [standardFeedsDispatch]
  );

  const checkIfParamsComboIsUnique = useCallback(
    (key: string, value: string, rowId: number) => {
      const currentStandardFeed = feeds.find((feed) => feed.id === rowId);
      const currentParamsComboOfCurrentStandardFeed: string[] = [];

      if (!currentStandardFeed) {
        return;
      }

      const paramsCombosOfAllStandardFeeds = feeds.map((feed) => {
        const paramsCombo: string[] = [];

        uniqueParamsCombo.forEach((param) => {
          paramsCombo.push(feed[param]);
        });

        return paramsCombo;
      });

      uniqueParamsCombo.forEach((param) => {
        if (param === key) {
          currentParamsComboOfCurrentStandardFeed.push(value);
        } else {
          currentParamsComboOfCurrentStandardFeed.push(currentStandardFeed[param]);
        }
      });

      return !paramsCombosOfAllStandardFeeds.some(
        (combo) => combo.toString() === currentParamsComboOfCurrentStandardFeed.toString()
      );
    },
    [feeds, uniqueParamsCombo]
  );

  const renderButtons = (): JSX.Element[] => {
    const isDisabled = (item: {
      id: number; disabled: boolean; purpose: string; text: string; type: string; }) => {
      let defaultVal: boolean | undefined;
  
      if (
        (item.purpose === "calcSFR" || item.purpose === "calcAllSFR") &&
        currentUser?.groups.includes(FEED_SMART_SUPER_ADMIN)
      ) {
        defaultVal = undefined;
      } else if (readOnlyMode || item.disabled) {
        defaultVal = true;
      }
      return defaultVal;
    };
  
    return [
      ...buttons.map((button) => {
        if (feeds.length) {
          return (
            <LMButton
              key={button.id}
              click={() => handleOnClick(button)}
              disabled={isDisabled(button)}
              text={t(button.text)}
              type="button"
            />
          );
        }
        return (
          <LMButton
            key={button.id}
            click={() => handleOnClick(button)}
            disabled
            text={t(button.text)}
            type="button"
          />
        );
      }),
  
      <LMButton
        key="save"
        click={handleOnRouteChange}
        disabled={!unsaved}
        text={t("Spara")}
        type="button"
      />
    ];
  };
  

  const makePropertiesEditeble = (properties: string[]): void => {
    const typeSpecsCopy = JSON.parse(JSON.stringify(standardFeedsTypeSpecs));

    if (currentUser?.groups.includes(FEED_SMART_SUPER_ADMIN)) {
      Object.keys(typeSpecsCopy).forEach((key) => {
        if (properties.includes(key)) {
          typeSpecsCopy[key] = {
            ...typeSpecsCopy[key],
            editable: true,
          };
        }
      });

    } else {
      Object.keys(typeSpecsCopy).forEach((key) => {
        if (properties.includes(key)) {
          typeSpecsCopy[key] = {
            ...typeSpecsCopy[key],
            editable: false,
          };
        }
      });
    }

    setStandardFeedsTypeSpecs(typeSpecsCopy);
  };

  useEffect(() => {
    if (!feeds.length) {
      fetchStandardFeeds();
    }
  }, [feeds.length, fetchStandardFeeds]);

  useEffect(() => {
    if (feedsToAdd.length) {
      const { id } = feedsToAdd.reduce((prev, current) =>
        prev.id && current.id && prev.id > current.id ? prev : current
      );
      setNewlyAddedFeedId(id);
    }
  }, [feedsToAdd]);

  useEffect(() => {
    if (lastUpdatedStandardFeed) {
      handleUpdatedStandardFeed();
    }
  }, [handleUpdatedStandardFeed, lastUpdatedStandardFeed]);

  useEffect(() => {
    makePropertiesEditeble(propertiesEditableBySuperAdmin);
  }, [currentUser]);

  const readOnlyClass = readOnlyMode ? "read-only" : "";

  const standardFeedsHiddenColumns = userHiddenTableColumns
    ? userHiddenTableColumns.standardFeedColumns
    : undefined;

  return (
    <BasePage pageName="standard-feeds">
      <Header
        title="standardFeeds"
        hideColumnsDropdown={feeds[0]}
        hideColumnsTableType="standardFeedColumns"
      />
      <div className="sub-header">
        {(isLoading || !feeds.length) && (
          <div className="spinner-wrapper">
            <Loader size="small" />
            <span className="margin-left-15">{t("loading")}</span>
          </div>
        )}

        <div className="buttons-wrapper">{renderButtons()}</div>
      </div>

      {showCow && (
        <>
          <iframe src="./moo.m4a" allow="autoplay" id="audio" title="Moo Sound" />
          <div className="showCow">
            <img src="./cow4.svg" alt="easterEgg" />
          </div>
        </>
      )}

      {feeds.length > 0 && (
        <div className={`sticky-col-3 ${readOnlyClass} sticky-header`}>
          <Table
            ref={tableRef}
            newlyAddedFeedId={newlyAddedFeedId}
            data={feeds}
            onSelectedRow={onSelectedRow}
            onUpdatedRow={onUpdatedRow}
            keyPrefix="feedParameters"
            typeSpecs={standardFeedsTypeSpecs}
            uniqueParamsCombo={uniqueParamsCombo}
            checkIfParamsComboIsUnique={checkIfParamsComboIsUnique}
            userHiddenTableColumns={standardFeedsHiddenColumns}
            getSortOrder={(tableSortOrder) => setCurrentSortOrder(tableSortOrder)}
          />
        </div>
      )}
    </BasePage>
  );
};

export default withAuthenticationRequired(StandardFeedsPage);
